import './Loading.css'

export class Loading {

    constructor() {
        this.node = document.createElement('div')
        this.node.setAttribute('id', 'loading')
        this.node.innerHTML = `<div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
        </div>`
    }

    show() {
        document.body.append(this.node)
    }

    hide() {
        document.body.removeChild(this.node)
    }

}