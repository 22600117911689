const ambientLight = new deck.AmbientLight({
    color: [255, 255, 255],
    intensity: 1
});

const pointLight1 = new deck.PointLight({
    color: [0, 0, 255],
    intensity: .5,
    position: [-0.144528, 49.739968, 80000]
});

const pointLight2 = new deck.PointLight({
    color: [0, 0, 255],
    intensity: .5,
    position: [-3.807751, 54.104682, 8000]
});

const lightingEffect = new deck.LightingEffect({ ambientLight, pointLight1, pointLight2 });

export const effects = [ lightingEffect ];