import { effects } from './effects.js'
import { LayersFactory } from './LayersFactory.js'

export default class DeckGL {

    constructor(mapBoxKey) {
        this.deck = new deck.DeckGL({
            mapboxApiAccessToken: mapBoxKey,
            mapStyle: 'mapbox://styles/nilbelec/ck69pjlzx2gdk1ipd39ih5leb',
            initialViewState: {
                longitude: 31.698303,
                latitude: 6.603223,
                zoom: 1.5,
                pitch: 30,
                bearing: -10
            },
            controller: true,
            effects: effects
        });
        this.layersFactory = new LayersFactory();
        this.previusType = '';
    }

    updateLayers(data, date, layers, onClick, onHover) {
        this.deck.setProps({
            layers: this.layersFactory.createLayers(data, date, layers, onClick, onHover)
        });
    }

    getLayersInfo() {
        return this.layersFactory.getLayersInfo();
    }

    _setPropsLayers(data, date, category, type, onHover, show) {
        this.deck.setProps({
            layers: [this.layersFactory.createLayer(data, date, category, type, onHover, show)]
        });

    }
}