import { CATEGORIES } from "../../categories";
import { sqrt, linear, posLinear, log10 } from "./functions";
import { COLORS_1 } from "./colors";

const FILL_TRANSPARENCY = 180;

const LINE_COLORS = {
    [CATEGORIES.recovered.id]: COLORS_1[CATEGORIES.recovered.id],
    [CATEGORIES.current.id]: COLORS_1[CATEGORIES.current.id],
    [CATEGORIES.deaths.id]: COLORS_1[CATEGORIES.deaths.id],
    [CATEGORIES.confirmed.id]: COLORS_1[CATEGORIES.confirmed.id]
}

const FILL_COLORS = {
    [CATEGORIES.recovered.id]: [...COLORS_1[CATEGORIES.recovered.id], FILL_TRANSPARENCY],
    [CATEGORIES.current.id]:  [...COLORS_1[CATEGORIES.current.id], FILL_TRANSPARENCY],
    [CATEGORIES.deaths.id]:  [...COLORS_1[CATEGORIES.deaths.id], FILL_TRANSPARENCY],
    [CATEGORIES.confirmed.id]:  [...COLORS_1[CATEGORIES.confirmed.id], FILL_TRANSPARENCY],
}
export class ScatterPlot {

    static id() {
        return 'scatterplot';
    }

    constructor() {
        this.id = ScatterPlot.id();
        this.name = 'ScatterPlot';
        this.categories = {
            [CATEGORIES.confirmed.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.confirmed.id] > 0 ? sqrt(dateValues[CATEGORIES.confirmed.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.confirmed.id],
                fillColor: FILL_COLORS[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.confirmedInc.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.confirmedInc.id] > 0 ? sqrt(dateValues[CATEGORIES.confirmedInc.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.confirmed.id],
                fillColor: FILL_COLORS[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.confirmedIncPer.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.confirmedIncPer.id] > 0 ? sqrt(dateValues[CATEGORIES.confirmedIncPer.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.confirmed.id],
                fillColor: FILL_COLORS[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.current.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.current.id] > 0 ? sqrt(dateValues[CATEGORIES.current.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.current.id], 
                fillColor: FILL_COLORS[CATEGORIES.current.id]
            },
            [CATEGORIES.currentPer.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.currentPer.id] > 0 ? sqrt(dateValues[CATEGORIES.currentPer.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.current.id], 
                fillColor: FILL_COLORS[CATEGORIES.current.id]
            },
            [CATEGORIES.currentInc.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.currentInc.id] > 0 ? sqrt(dateValues[CATEGORIES.currentInc.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.current.id], 
                fillColor: FILL_COLORS[CATEGORIES.current.id]
            },
            [CATEGORIES.currentIncPer.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.currentIncPer.id] > 0 ? sqrt(dateValues[CATEGORIES.currentIncPer.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.current.id], 
                fillColor: FILL_COLORS[CATEGORIES.current.id]
            },
            [CATEGORIES.recovered.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.recovered.id] > 0 ? sqrt(dateValues[CATEGORIES.recovered.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.recovered.id], 
                fillColor: FILL_COLORS[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredPer.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.recoveredPer.id] > 0 ? sqrt(dateValues[CATEGORIES.recoveredPer.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.recovered.id], 
                fillColor: FILL_COLORS[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredInc.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.recoveredInc.id] > 0 ? sqrt(dateValues[CATEGORIES.recoveredInc.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.recovered.id], 
                fillColor: FILL_COLORS[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredIncPer.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.recoveredIncPer.id] > 0 ? sqrt(dateValues[CATEGORIES.recoveredIncPer.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.recovered.id], 
                fillColor: FILL_COLORS[CATEGORIES.recovered.id]
            },
            [CATEGORIES.deaths.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.deaths.id] > 0 ? sqrt(dateValues[CATEGORIES.deaths.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.deaths.id], 
                fillColor: FILL_COLORS[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsPer.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.deathsPer.id] > 0 ? sqrt(dateValues[CATEGORIES.deathsPer.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.deaths.id], 
                fillColor: FILL_COLORS[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsInc.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.deathsInc.id] > 0 ? sqrt(dateValues[CATEGORIES.deathsInc.id]) : 0, 
                lineColor: LINE_COLORS[CATEGORIES.deaths.id], 
                fillColor: FILL_COLORS[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsIncPer.id]: { 
                getRadius: dateValues => dateValues[CATEGORIES.deathsIncPer.id] > 0 ? sqrt(dateValues[CATEGORIES.deathsIncPer.id]) : 0,
                lineColor: LINE_COLORS[CATEGORIES.deaths.id], 
                fillColor: FILL_COLORS[CATEGORIES.deaths.id]
            }
        }
    }

    createLayer(data, date, layer, onClick, onHover) {
        return new deck.ScatterplotLayer({
            id: layer.id,
            getLineColor: this.categories[layer.category].lineColor,
            getFillColor: this.categories[layer.category].fillColor,
            getRadius: d => this.categories[layer.category].getRadius(d[date]),
            autoHighlight: true,
            pickable: true,
            stroke: true,
            updateTriggers: {
                getRadius: [date, layer]
            },
            transitions: {
                getRadius: 1000,
                getFillColor: 1000,
                getLineColor: 1000
            },
            onHover: info => {
                if (!info.object){
                    onHover(undefined)
                    return
                }
                const val = {
                    value: info.object[date][layer.category],
                    category: layer.category,
                    x: info.x,
                    y: info.y,
                    clickable: true,
                }
                onHover(val)
            },
            onClick: info => {
                if (info.object)
                    onClick([info.object]);
                else
                    onClick([]);
            },
            data,
            ...layer.options
        });
    }

    getInfo() {
        return {
            id: this.id,
            name: this.name,
            options: [
                { name: 'Opacity', id: 'opacity', type: 'range', min: '0', max: '1', step: '0.01', val: '0.7' },
                { name: 'Radius Scale', id: 'radiusScale', type: 'range', min: '1', max: '50000', step: '1', val: '5000' },
                { name: 'Radius Min Pixels', id: 'radiusMinPixels', type: 'range', min: '1', max: '100', step: '1', val: '5' }
            ],
            categories: Object.keys(this.categories).map(k => { return { id: k } } )
        }
    }

}