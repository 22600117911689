import { CATEGORIES } from "../../categories"

export const COLORS_6 = {
    [CATEGORIES.confirmed.id]: [[254, 237, 222], [253, 208, 162], [253, 174, 107], [253, 141, 60], [230, 85, 13], [166, 54, 3]],
    [CATEGORIES.current.id]: [[254, 229, 217], [252, 187, 161], [252, 146, 114], [251, 106, 74], [222, 45, 38], [165, 15, 21]],
    [CATEGORIES.recovered.id]: [[237, 248, 233], [199, 233, 192], [161, 217, 155], [116, 196, 118], [49, 163, 84], [0, 109, 44]],
    [CATEGORIES.deaths.id]: [[242, 240, 247], [218, 218, 235], [188, 189, 220], [158, 154, 200], [117, 107, 177], [84, 39, 143]]
}

export const COLORS_1 = {
    [CATEGORIES.confirmed.id]: COLORS_6[CATEGORIES.confirmed.id][5],
    [CATEGORIES.current.id]: COLORS_6[CATEGORIES.current.id][5],
    [CATEGORIES.recovered.id]: COLORS_6[CATEGORIES.recovered.id][5],
    [CATEGORIES.deaths.id]: COLORS_6[CATEGORIES.deaths.id][5]
}