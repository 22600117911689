import { CATEGORIES } from "./categories";

const BASE_URL = 'https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/csse_covid_19_data/csse_covid_19_time_series/time_series_covid19_'
const CONFIRMED_BASE_URL = BASE_URL + 'confirmed_global.csv'
const DEATHS_BASE_URL = BASE_URL + 'deaths_global.csv'
const RECOVERED_BASE_URL = BASE_URL + 'recovered_global.csv'

const INPUT_STATE = 'Province/State'
const INPUT_COUNTRY = 'Country/Region'
const INPUT_LAT = 'Lat'
const INPUT_LONG = 'Long'
const INPUT_DATES_START_INDEX = 4
const INPUT_DATE_FORMATS = ['M/D/YY', 'M/D/YYYY']

export const DATE_KEY_FORMAT = 'YYYYMMDD'

export async function getData() {
    const confirmedProm = d3.csv(CONFIRMED_BASE_URL)
    const deathsProm = d3.csv(DEATHS_BASE_URL)
    const recoveredProm = d3.csv(RECOVERED_BASE_URL)

    return Promise.all([confirmedProm, deathsProm, recoveredProm]).then(results => {
        const datesKeys = {}
        const tmp = {}
        const keysIdx = {}
        results.forEach((result, resultIdx) => {
            result.forEach((row, rowIdx) => {
                const key = row[INPUT_STATE] + row[INPUT_COUNTRY]
                if (!tmp[key]) {
                    tmp[key] = prepareNewEntry(row)
                }
                if (!keysIdx[key]) {
                    keysIdx[key] = [undefined, undefined, undefined]
                }
                keysIdx[key][resultIdx] = rowIdx
                for (let i = INPUT_DATES_START_INDEX; i < Object.keys(row).length; i++) {
                    const date = Object.keys(row)[i]
                    const dateKey = prepareDateKey(date)
                    if (!datesKeys[dateKey]) {
                        datesKeys[dateKey] = [undefined, undefined, undefined]
                    }
                    datesKeys[dateKey][resultIdx] = date
                }
            })
        })
        
        const global = {}
        Object.keys(tmp).forEach(key => {
            const zoneIdx = keysIdx[key]

            const zoneConfirmed = zoneIdx[0] !== undefined ? results[0][zoneIdx[0]] : {}
            const zoneDeaths = zoneIdx[1] !== undefined ? results[1][zoneIdx[1]] : {}
            const zoneRecovered = zoneIdx[2] !== undefined ? results[2][zoneIdx[2]] : {}

            let prevDateKey
            Object.keys(datesKeys).forEach(dateKey => {
                const dateIdx = datesKeys[dateKey]

                const prevData = prevDateKey ? tmp[key][prevDateKey] : newValues()

                const confirmed = dateIdx[0] !== undefined && zoneConfirmed[dateIdx[0]] !== undefined ? Number(zoneConfirmed[dateIdx[0]]) : prevData[CATEGORIES.confirmed.id]
                const confirmedInc = confirmed - prevData[CATEGORIES.confirmed.id]
                const confirmedIncPer = calcPerc(confirmedInc, prevData[CATEGORIES.confirmed.id])

                const recovered = dateIdx[2] !== undefined && zoneRecovered[dateIdx[2]] !== undefined ? Number(zoneRecovered[dateIdx[2]]) : prevData[CATEGORIES.recovered.id]
                const recoveredPer = calcPerc(recovered, confirmed)
                const recoveredInc = recovered - prevData[CATEGORIES.recovered.id]
                const recoveredIncPer = calcPerc(recoveredInc, prevData[CATEGORIES.recovered.id])

                const deaths = dateIdx[1] !== undefined && zoneDeaths[dateIdx[1]] !== undefined ? Number(zoneDeaths[dateIdx[1]]) : prevData[CATEGORIES.deaths.id]
                const deathsPer = calcPerc(deaths, confirmed)
                const deathsInc = deaths - prevData[CATEGORIES.deaths.id]
                const deathsIncPer = calcPerc(deathsInc, prevData[CATEGORIES.deaths.id])

                const current = confirmed - deaths - recovered
                const currentPer = calcPerc(current, confirmed)
                const currentInc = current - prevData[CATEGORIES.current.id]
                const currentIncPer = calcPerc(currentInc, prevData[CATEGORIES.current.id])

                tmp[key][dateKey] = {
                    [CATEGORIES.confirmed.id]: confirmed,
                    [CATEGORIES.confirmedInc.id]: confirmedInc,
                    [CATEGORIES.confirmedIncPer.id]: confirmedIncPer,

                    [CATEGORIES.recovered.id]: recovered,
                    [CATEGORIES.recoveredPer.id]: recoveredPer,
                    [CATEGORIES.recoveredInc.id]: recoveredInc,
                    [CATEGORIES.recoveredIncPer.id]: recoveredIncPer,

                    [CATEGORIES.deaths.id]: deaths,
                    [CATEGORIES.deathsPer.id]: deathsPer,
                    [CATEGORIES.deathsInc.id]: deathsInc,
                    [CATEGORIES.deathsIncPer.id]: deathsIncPer,

                    [CATEGORIES.current.id]: current,
                    [CATEGORIES.currentPer.id]: currentPer,
                    [CATEGORIES.currentInc.id]: currentInc,
                    [CATEGORIES.currentIncPer.id]: currentIncPer,
                }

                if (!global[dateKey]) {
                    global[dateKey] = newValues()
                }
                const dateGlobal = global[dateKey]
                const prevGlobal = prevDateKey ? global[prevDateKey] : newValues()

                dateGlobal[CATEGORIES.confirmed.id] += confirmed
                dateGlobal[CATEGORIES.confirmedInc.id] += confirmedInc
                dateGlobal[CATEGORIES.confirmedIncPer.id] = calcPerc(dateGlobal[CATEGORIES.confirmedInc.id], prevGlobal[CATEGORIES.confirmed.id])

                dateGlobal[CATEGORIES.recovered.id] += recovered
                dateGlobal[CATEGORIES.recoveredPer.id] = calcPerc(dateGlobal[CATEGORIES.recovered.id], prevGlobal[CATEGORIES.confirmed.id])
                dateGlobal[CATEGORIES.recoveredInc.id] += recoveredInc
                dateGlobal[CATEGORIES.recoveredIncPer.id] = calcPerc(dateGlobal[CATEGORIES.recoveredInc.id], prevGlobal[CATEGORIES.recovered.id])

                dateGlobal[CATEGORIES.deaths.id] += deaths
                dateGlobal[CATEGORIES.deathsPer.id] = calcPerc(dateGlobal[CATEGORIES.deaths.id], prevGlobal[CATEGORIES.confirmed.id])
                dateGlobal[CATEGORIES.deathsInc.id] += deathsInc
                dateGlobal[CATEGORIES.deathsIncPer.id] = calcPerc(dateGlobal[CATEGORIES.deathsInc.id], prevGlobal[CATEGORIES.deaths.id])

                dateGlobal[CATEGORIES.current.id] += current
                dateGlobal[CATEGORIES.currentPer.id] = calcPerc(dateGlobal[CATEGORIES.current.id], prevGlobal[CATEGORIES.confirmed.id])
                dateGlobal[CATEGORIES.currentInc.id] += currentInc
                dateGlobal[CATEGORIES.currentIncPer.id] = calcPerc(dateGlobal[CATEGORIES.currentInc.id], prevGlobal[CATEGORIES.current.id])

                global[dateKey] = dateGlobal

                prevDateKey = dateKey
            })
        })

        const data = Object.values(tmp)
        const currentDate = Object.keys(datesKeys)[Object.keys(datesKeys).length - 1]
        return { data, global, currentDate }
    })
}

function newValues() {
    return {
        [CATEGORIES.confirmed.id]: 0,
        [CATEGORIES.confirmedInc.id]: 0,
        [CATEGORIES.confirmedIncPer.id]: 0,
        [CATEGORIES.current.id]: 0,
        [CATEGORIES.currentPer.id]: 0,
        [CATEGORIES.currentInc.id]: 0,
        [CATEGORIES.currentIncPer.id]: 0,
        [CATEGORIES.recovered.id]: 0,
        [CATEGORIES.recoveredPer.id]: 0,
        [CATEGORIES.recoveredInc.id]: 0,
        [CATEGORIES.recoveredIncPer.id]: 0,
        [CATEGORIES.deaths.id]: 0,
        [CATEGORIES.deathsPer.id]: 0,
        [CATEGORIES.deathsInc.id]: 0,
        [CATEGORIES.deathsIncPer.id]: 0
    }
}

function prepareNewEntry(d) {
    return {
        state: d[INPUT_STATE],
        country: d[INPUT_COUNTRY],
        position: preparePosition(d)
    }
}

function preparePosition(d) {
    return [Number(d[INPUT_LONG]), Number(d[INPUT_LAT])]
}

function calcPerc(val, total) {
    if (!total) { return 0 }
    return (Math.round((val / total) * 1000) / 10)
}

function prepareDateKey(text) {
    const f = INPUT_DATE_FORMATS.find(f => moment(text, f).isValid())
    return moment(text, f).format(DATE_KEY_FORMAT)
}
