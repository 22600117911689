import { getData, DATE_KEY_FORMAT } from './data.js'
import MapController from './map/MapController.js'
import { CATEGORIES } from './categories.js'
import { Hexagon, Heatmap, Contour, ScatterPlot, ScreenGrid } from './map/layers'
import { calcPercent, formatIncrement, formatIncrementPercent } from './utils'

import './app.css'
import { Loading } from './loading/Loading.js'

const MAPBOX_KEY = process.env.MAPBOX_KEY

const loading = new Loading();
loading.show();

window.addEventListener('load', async function () {
    const map = new MapController(MAPBOX_KEY)
    map.onLayerClick = (data) => showModal(data, currentDate)
    map.onLayerHover = showTooltip
    map.getLayersInfo().forEach(appendLayer)

    const { data, global, currentDate } = await getData()

    $('#date').text(moment(currentDate, DATE_KEY_FORMAT).format('MMMM Do, YYYY'))

    $('#layers-container .layer-update').on('change', () => map.update(data, currentDate, prepareLayers()))
    $('#layers-container .layer-option').on('input', () => map.update(data, currentDate, prepareLayers()))
    $('#layers-container .layer-visibility').on('click', function () {
        $(this).find('i').toggleClass('fa-eye fa-eye-slash')
        $(this).closest('.layer-row').toggleClass('op-05')
        map.update(data, currentDate, prepareLayers())
    })
    $('#layers-container select').select2({
        width: '100%',
        minimumResultsForSearch: Infinity
    })

    Sortable.create(document.getElementById('layers-container'), {
        animation: 150,
        handle: '.drag-handle',
        dragClass: 'dragged',
        chosenClass: 'op-01',
        onChange: () => map.update(data, currentDate, prepareLayers())
    })

    updateGlobal(global, currentDate)
    createChart(global, '#global-chart')
    map.update(data, currentDate, prepareLayers());

    $('.hide-on-load').removeClass('hide-on-load');
    loading.hide();
})

function prepareLayers() {
    const layers = []
    $('#active-layers').empty();
    $('#layers-container .layer-row').each((i, el) => {
        if (!$(el).find('.layer-visibility > i').hasClass('fa-eye'))
            return;
        const options = {}
        $(el).find('input.layer-option').each(function (i, o) {
            const name = $(o).prop('name')
            options[name] = +$(o).val()
        })
        layers.push({
            id: $(el).data('id'),
            category: $(el).find('.layer-category').val(),
            type: $(el).data('id'),
            options
        })
    })
    return layers
}

function updateGlobal(global, date) {
    $('#current-total').text(global[date].current)
    $('#current-per').html(global[date].currentPer + '%')
    $('#current-inc').html(formatIncrement(global[date].currentInc))
    $('#current-inc-per').html(formatIncrement(global[date].currentIncPer) + '%')

    $('#confirmed-total').text(global[date].confirmed)
    $('#confirmed-per').text(global[date].confirmedPer + '%')
    $('#confirmed-inc').html(formatIncrement(global[date].confirmedInc))
    $('#confirmed-inc-per').html(formatIncrement(global[date].confirmedIncPer) + '%')

    $('#recovered-total').text(global[date].recovered)
    $('#recovered-per').text(global[date].recoveredPer + '%')
    $('#recovered-inc').html(formatIncrement(global[date].recoveredInc))
    $('#recovered-inc-per').html(formatIncrement(global[date].recoveredIncPer) + '%')

    $('#deaths-total').text(global[date].deaths)
    $('#deaths-per').text(global[date].deathsPer + '%')
    $('#deaths-inc').html(formatIncrement(global[date].deathsInc))
    $('#deaths-inc-per').html(formatIncrement(global[date].deathsIncPer) + '%')
}

const INITIAL_LAYERS = {
    [Hexagon.id()]: CATEGORIES.current.id,
}

function appendLayer(layerInfo) {
    let visible = INITIAL_LAYERS[layerInfo.id] ? true : false;
    let category = visible ? INITIAL_LAYERS[layerInfo.id] : '';

    const row = $('<div class="row align-items-end layer-row py-1 mx-n1 ' + (visible ? '' : 'op-05') + '"></div>')
    row.data('id', layerInfo.id)
    row.data('name', layerInfo.name)

    row.append('<div class="col-auto px-1"><i class="fa fa-bars fa-fw drag-handle has-tooltip" title="Drag to change the layer drawing order"></i></div>')
    const colRight = $('<div class="col px-1"></div>');
    row.append(colRight)
    const optionsRow = $('<div class="row mx-n1"></div>');
    colRight.append(optionsRow)
    optionsRow.append('<div class="col-12 col-md-3 col-lg-2 px-1 font-weight-bolder">' + layerInfo.name + '</div>')
    const colCategories = $('<div class="col px-1"></div>')
    const selectCategories = $('<select class="form-control form-control-sm layer-category layer-update"></select>')
    layerInfo.categories.forEach(c => {
        selectCategories.append('<option value="' + c.id + '" ' + (category === c.id ? 'selected' : '') + '>' + CATEGORIES[c.id].name + '</option>')
    })
    colCategories.append(selectCategories)
    optionsRow.append(colCategories)
    const btnsCol = $('<div class="col-auto px-1"></div>')
    btnsCol.append('<button class="btn btn-sm btn-outline-light has-tooltip" title="Show/hide layer settings" data-toggle="collapse" data-target="#' + layerInfo.id + '-options"><i class="fa fa-sliders fa-fw"></i></button>')
    btnsCol.append('<button class="btn btn-sm btn-outline-light layer-visibility has-tooltip" title="Toggle layer visibility"><i class="fa ' + (visible ? 'fa-eye' : 'fa-eye-slash') + ' fa-fw"></i></button>')
    optionsRow.append(btnsCol)

    row.append('<div class="w-100"></div>')

    const options = $('<div class="col-12 collapse pt-2 px-1" id="' + layerInfo.id + '-options"></div>')
    layerInfo.options.forEach(o => {
        const optionRow = $('<div class="form-group row"></div>')
        optionRow.append('<label class="col-sm-4 col-lg-3 col-form-label text-right">' + o.name + '</label>')
        const optionCol = $('<div class="col"></div>')
        const input = $('<input class="layer-option form-control" type="range"/>')
        input.prop('name', o.id)
        input.prop('min', o.min)
        input.prop('max', o.max)
        input.prop('step', o.step)
        input.prop('value', o.val)
        optionCol.append(input)
        optionRow.append(optionCol)
        options.append(optionRow)
    })
    row.append(options)

    $('#layers-container').append(row)
    row.find('.has-tooltip').tooltip({ boundary: 'viewport', trigger: 'hover', delay: { "show": 600, "hide": 0 } })
}

function showTooltip(info) {
    if (!info || !info.value) {
        $('#tooltip').hide()
        return
    }
    const $tooltip = $('#tooltip').css('top', info.y + 'px').css('left', info.x + 'px').empty()
    $tooltip.append('<div id="tooltip-title">' + CATEGORIES[info.category].name + '</div>')
    $tooltip.append('<div id="tooltip-value">' + CATEGORIES[info.category].formatValue(info.value) + '</div>')
    if (info.clickable) {
        $tooltip.append('<small class="d-block p-1">(click to see details)</small>')
    }
    $tooltip.show();
}

function showModal(data, date) {
    if (!data || !data.length || !date) { return }

    const zoneInfoEl = $('<div id="zone-info"></div>')
    $('#info-modal .modal-body').empty().append(zoneInfoEl)

    data.forEach(p => {
        let zone = p.country
        if (p.state) {
            zone = p.state + ' (' + zone + ')'
        }
        const values = p[date]

        const elementEl = $('<div class="element"></div>')
        zoneInfoEl.append(elementEl)

        elementEl.append('<div class="title">' + zone + '</div>')

        const row = $('<div class="row py-1 mx-n1"></div')
        elementEl.append(row)

        const leftCol = $('<div class="col-lg-6 px-1"></div')
        row.append(leftCol)

        const confirmedRow = $('<div class="row mx-n1 pb-1"></div>')
        confirmedRow.append('<div class="col px-1"><span class="w-100 badge badge-confirmed fs-big">' + values.confirmed + '</span></div>')
        confirmedRow.append('<div class="col px-1"></div>')
        confirmedRow.append('<div class="col px-1"><span class="w-100 badge badge-confirmed">' + formatIncrement(values.confirmedInc) + '</span></div>')
        confirmedRow.append('<div class="col px-1"><span class="w-100 badge badge-confirmed">' + formatIncrementPercent(values.confirmedIncPer / 100) + '</span></div>')

        const currentRow = $('<div class="row mx-n1 pb-1"></div>')
        currentRow.append('<div class="col px-1"><span class="w-100 badge badge-current fs-big">' + values.current + '</span></div>')
        currentRow.append('<div class="col px-1"><span class="w-100 badge badge-current">' + calcPercent(values.current, values.confirmed) + '</span></div>')
        currentRow.append('<div class="col px-1"><span class="w-100 badge badge-current">' + formatIncrement(values.currentInc) + '</span></div>')
        currentRow.append('<div class="col px-1"><span class="w-100 badge badge-current">' + formatIncrementPercent(values.currentIncPer / 100) + '</span></div>')

        const recoveredRow = $('<div class="row mx-n1 pb-1"></div>')
        recoveredRow.append('<div class="col px-1"><span class="w-100 badge badge-recovered fs-big">' + values.recovered + '</span></div>')
        recoveredRow.append('<div class="col px-1"><span class="w-100 badge badge-recovered">' + calcPercent(values.recovered, values.confirmed) + '</span></div>')
        recoveredRow.append('<div class="col px-1"><span class="w-100 badge badge-recovered">' + formatIncrement(values.recoveredInc) + '</span></div>')
        recoveredRow.append('<div class="col px-1"><span class="w-100 badge badge-recovered">' + formatIncrementPercent(values.recoveredIncPer / 100) + '</span></div>')

        const deathRow = $('<div class="row mx-n1"></div>')
        deathRow.append('<div class="col px-1"><span class="w-100 badge badge-deaths fs-big">' + values.deaths + '</span></div>')
        deathRow.append('<div class="col px-1"><span class="w-100 badge badge-deaths">' + calcPercent(values.deaths, values.confirmed) + '</span></div>')
        deathRow.append('<div class="col px-1"><span class="w-100 badge badge-deaths">' + formatIncrement(values.deathsInc) + '</span></div>')
        deathRow.append('<div class="col px-1"><span class="w-100 badge badge-deaths">' + formatIncrementPercent(values.deathsIncPer / 100) + '</span></div>')

        leftCol.append(confirmedRow)
        leftCol.append(currentRow)
        leftCol.append(recoveredRow)
        leftCol.append(deathRow)

        const rightCol = $('<div class="col px-1"></div>')
        row.append(rightCol)

        const chartContainer = $('<div class="chart"></div>')
        rightCol.append(chartContainer)

        setTimeout(() => {
            chartContainer.height(leftCol.height())
            createChart(p, chartContainer[0])
        }, 300)
    })

    $('#info-modal').modal('show')
}

function createChart(data, selector) {
    const series = [[], [], []]
    for (var k in data) {
        if (!k.match(/\d+/g)) { continue }
        series[0].push(data[k].current)
        series[1].push(data[k].recovered)
        series[2].push(data[k].deaths)
    }
    var options = {
        stackBars: true,
        axisX: {
            offset: 0,
            showGrid: false,
            showLabel: false
        },
        axisY: {
            offset: 0,
            showGrid: false,
            showLabel: false
        },
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    }
    const chart = new Chartist.Bar(selector, {
        series: series
    }, options)
    chart.on('draw', function (data) {
        if (data.type === 'bar') {
            data.element.animate({
                y2: {
                    begin: 1000 * data.seriesIndex,
                    dur: 3000,
                    from: data.y1,
                    to: data.y2,
                    easing: Chartist.Svg.Easing.easeOutQuint
                }
            });
        }
    });
    const chartContainer = $(selector);
    setTimeout(() => {
        const w = chartContainer.find('svg').width();
        chartContainer.find('g.ct-series').each((i, el) => {
            const lines = $(el).children('line')
            lines.css('stroke-width', (w / lines.length) + 'px')
        });
    }, 100)
    return chart;
}
