import { COLORS_6 } from "./colors";
import { sqrt, linear, posLinear } from "./functions";
import { CATEGORIES } from "../../categories";
import { SUM, MEAN } from "./aggregations";

const material = {
    ambient: 0.64,
    diffuse: 0.6,
    shininess: 32,
    specularColor: [51, 51, 51]
};

export class Hexagon {

    static id() {
        return 'hexagon';
    }

    constructor() {
        this.id = Hexagon.id();
        this.name = 'Hexagon';
        this.categories = {
            [CATEGORIES.confirmed.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.confirmed.id]),
                getColorWeight: dateValues => sqrt(dateValues[CATEGORIES.confirmed.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.confirmedInc.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.confirmedInc.id]),
                getColorWeight: dateValues => linear(dateValues[CATEGORIES.confirmedInc.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.confirmedIncPer.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.confirmedIncPer.id]),
                getColorWeight: dateValues => linear(dateValues[CATEGORIES.confirmedIncPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.current.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.current.id]),
                getColorWeight: dateValues => sqrt(dateValues[CATEGORIES.current.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentPer.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.currentPer.id]),
                getColorWeight: dateValues => linear(dateValues[CATEGORIES.currentPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentInc.id]: {
                getElevationWeight: dateValues => posLinear(dateValues[CATEGORIES.currentInc.id]),
                getColorWeight: dateValues => posLinear(dateValues[CATEGORIES.currentInc.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentIncPer.id]: {
                getElevationWeight: dateValues => posLinear(dateValues[CATEGORIES.currentIncPer.id]),
                getColorWeight: dateValues => posLinear(dateValues[CATEGORIES.currentIncPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.recovered.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.recovered.id]),
                getColorWeight: dateValues => sqrt(dateValues[CATEGORIES.recovered.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredPer.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.recoveredPer.id]),
                getColorWeight: dateValues => linear(dateValues[CATEGORIES.recoveredPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredInc.id]: {
                getElevationWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredInc.id]),
                getColorWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredInc.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredIncPer.id]: {
                getElevationWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredIncPer.id]),
                getColorWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredIncPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.deaths.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.deaths.id]),
                getColorWeight: dateValues => sqrt(dateValues[CATEGORIES.deaths.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsPer.id]: {
                getElevationWeight: dateValues => linear(dateValues[CATEGORIES.deathsPer.id]),
                getColorWeight: dateValues => linear(dateValues[CATEGORIES.deathsPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsInc.id]: {
                getElevationWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsInc.id]),
                getColorWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsInc.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsIncPer.id]: {
                getElevationWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsIncPer.id]),
                getColorWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsIncPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            }
        }
    }

    createLayer(data, date, layer, onClick, onHover) {
        return new deck.HexagonLayer({
            id: layer.id,
            getElevationWeight: d => this.categories[layer.category].getElevationWeight(d[date]),
            getColorWeight: d => this.categories[layer.category].getColorWeight(d[date]),
            colorRange: this.categories[layer.category].colorRange,
            colorAggregation: this.categories[layer.category].aggregation,
            elevationAggregation: this.categories[layer.category].aggregation,
            elevationRange: [1, 50000],
            extruded: true,
            autoHighlight: true,
            pickable: true,
            updateTriggers: {
                getElevationWeight: [date, layer],
                getColorWeight: [date, layer],
            },
            transitions: {
                getElevationWeight: 1000,
                getColorWeight: 1000,
            },
            onHover: info => {
                if (!info.object){
                    onHover(undefined)
                    return
                }
                const val = {
                    value: info.object.elevationValue,
                    category: layer.category,
                    x: info.x,
                    y: info.y,
                    clickable: true,
                }
                onHover(val)
            },
            onClick: info => {
                if (info.object)
                    onClick(info.object.points);
                else
                    onClick([]);
            },
            material,
            data,
            ...layer.options
        });
    }

    getInfo() {
        return {
            id: this.id,
            name: this.name,
            options: [
                { name: 'Opacity', id: 'opacity', type: 'range', min: '0', max: '1', step: '0.01', val: '0.7' },
                { name: 'Coverage', id: 'coverage', type: 'range', min: '0', max: '1', step: '0.01', val: '0.7' },
                { name: 'Radius', id: 'radius', type: 'range', min: '50000', max: '2000000', step: '1000', val: '200000' },
                { name: 'Elevation Scale', id: 'elevationScale', type: 'range', min: '0', max: '100', step: '1', val: '20' }
            ],
            categories: Object.keys(this.categories).map(k => { return { id: k } })
        }
    }
}