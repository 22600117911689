import { COLORS_6 } from "./colors";
import { sqrt, linear, posLinear } from "./functions";
import { CATEGORIES } from "../../categories";

export class Heatmap {

    static id() {
        return 'heatmap';
    }

    constructor() {
        this.id = Heatmap.id();
        this.name = 'Heatmap';
        this.categories = {
            [CATEGORIES.confirmed.id]: { 
                getWeight: dateValues => sqrt(dateValues[CATEGORIES.confirmed.id]), 
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.confirmedInc.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.confirmedInc.id]), 
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.confirmedIncPer.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.confirmedIncPer.id]), 
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.current.id]: { 
                getWeight: dateValues => sqrt(dateValues[CATEGORIES.current.id]), 
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentPer.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.currentPer.id]), 
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentInc.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.currentInc.id]), 
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentIncPer.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.currentIncPer.id]), 
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.recovered.id]: { 
                getWeight: dateValues => sqrt(dateValues[CATEGORIES.recovered.id]), 
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredPer.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.recoveredPer.id]), 
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredInc.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredInc.id]), 
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredIncPer.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredIncPer.id]), 
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.deaths.id]: { 
                getWeight: dateValues => sqrt(dateValues[CATEGORIES.deaths.id]), 
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsPer.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.deathsPer.id]), 
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsInc.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsInc.id]), 
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsIncPer.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsIncPer.id]), 
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            }
        }
    }

    createLayer(data, date, layer, onClick, onHover) {
        return new deck.HeatmapLayer({
            id: layer.id,
            pickable: true,
            getWeight: d => this.categories[layer.category].getWeight(d[date]),
            colorRange: this.categories[layer.category].colorRange,
            updateTriggers: {
                getWeight: [date, layer]
            },
            data,
            ...layer.options
        });
    }

    getInfo() {
        return {
            id: this.id,
            name: this.name,
            options: [
                { name: 'Opacity', id: 'opacity', type: 'range', min: '0', max: '1', step: '0.01', val: '0.7' },
                { name: 'Intensity', id: 'intensity', type: 'range', min: '0', max: '100', step: '0.1', val: '1' },
                { name: 'Radius Pixels', id: 'radiusPixels', type: 'range', min: '1', max: '200', step: '1', val: '40' }
            ],
            categories: Object.keys(this.categories).map(k => { return { id: k } } )
        }
    }
}