import DeckGL from './DeckGL.js'

export default class MapController {
    
    constructor(mapBoxKey){
        this.deck = new DeckGL(mapBoxKey);
        this.onLayerClick;
        this.onLayerHover;
    }

    update(data, date, layers) {
        this.deck.updateLayers(data, date, layers, this.onLayerClick, this.onLayerHover);
    }

    getLayersInfo() {
        return this.deck.getLayersInfo();
    }

}