import { formatPercent, formatIncrementPercent, formatIncrement } from './utils'

export const CATEGORIES = {
    confirmed: {
        id: 'confirmed',
        name: 'Number of confirmed cases',
        formatValue: value => value,
    },
    confirmedInc: {
        id: 'confirmedInc',
        name: 'Confirmed day increment',
        formatValue: value => formatIncrement(value),
    },
    confirmedIncPer: {
        id: 'confirmedIncPer',
        name: 'Confirmed day increment %',
        formatValue: value => formatIncrementPercent(value/100),
    },
    current: {
        id: 'current',
        name: 'Number of active cases',
        formatValue: value => value,
    },
    currentPer: {
        id: 'currentPer',
        name: 'Active vs confirmed %',
        formatValue: value => formatPercent(value/100),
    },
    currentInc: {
        id: 'currentInc',
        name: 'Active day increment',
        formatValue: value => formatIncrement(value),
    },
    currentIncPer: {
        id: 'currentIncPer',
        name: 'Active day increment %',
        formatValue: value => formatIncrementPercent(value/100),
    },
    recovered: {
        id: 'recovered',
        name: 'Number of recovered cases',
        formatValue: value => value,
    },
    recoveredPer: {
        id: 'recoveredPer',
        name: 'Recovered vs confirmed %',
        formatValue: value => formatPercent(value/100),
    },
    recoveredInc: {
        id: 'recoveredInc',
        name: 'Recovered day increment',
        formatValue: value => formatIncrement(value),
    },
    recoveredIncPer: {
        id: 'recoveredIncPer',
        name: 'Recovered day increment %',
        formatValue: value => formatIncrementPercent(value/100),
    },
    deaths: {
        id: 'deaths',
        name: 'Number of deaths',
        formatValue: value => value,
    },
    deathsPer: {
        id: 'deathsPer',
        name: 'Deaths vs confirmed %',
        formatValue: value => formatPercent(value/100),
    },
    deathsInc: {
        id: 'deathsInc',
        name: 'Deaths day increment',
        formatValue: value => formatIncrement(value),
    },
    deathsIncPer: {
        id: 'deathsIncPer',
        name: 'Deaths day increment %',
        formatValue: value => formatIncrementPercent(value/100),
    }
}