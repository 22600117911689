import { Hexagon, ScreenGrid, Contour, Heatmap, ScatterPlot } from "./layers/index.js";

export class LayersFactory {

    constructor() {
        this.layers = {}
        this.layers[ScatterPlot.id()] = new ScatterPlot();
        this.layers[ScreenGrid.id()] = new ScreenGrid();
        this.layers[Contour.id()] = new Contour();
        this.layers[Heatmap.id()] = new Heatmap();
        this.layers[Hexagon.id()] = new Hexagon();
    }

    createLayers(data, date, layers, onClick, onHover) {
        if (!layers || !layers.length)
            return [];
        return layers.filter(l => this.layers[l.id]).map(l => this.layers[l.type].createLayer(data, date, l, onClick, onHover));
    }

    getLayersInfo() {
        return Object.values(this.layers).map(l => l.getInfo())
    }
}