export function calcPercent(val, total) {
    if (!val) { return 0 + '%' }
    return formatPercent(val / total);
}

export function formatPercent(val) {
    if (!val) { return 0 + '%' }
    return (Math.round(val * 1000) / 10) + '%'
}

export function formatIncrement(val) {
    if (!val) { return '<i class="fa fa-angle-double-right fa-fw"></i>' + 0 }
    if (val > 0) { return '<i class="fa fa-angle-double-up fa-fw"></i>' + val }
    return '<i class="fa fa-angle-double-down fa-fw"></i>' + Math.abs(val)
}

export function formatIncrementPercent(val) {
    if (!val) { return '<i class="fa fa-angle-double-right fa-fw"></i>' + formatPercent(val) }
    if (val > 0) { return '<i class="fa fa-angle-double-up fa-fw"></i>' + formatPercent(val) }
    return '<i class="fa fa-angle-double-down fa-fw"></i>' + formatPercent(Math.abs(val))
}