import { COLORS_6 } from "./colors";
import { sqrt, linear, posLinear } from "./functions";
import { CATEGORIES } from "../../categories";

const CONTOURS = {
    PERC: (key) => [
        { threshold: [1, 20], color: COLORS_6[key][1] },
        { threshold: [20, 40], color: COLORS_6[key][2] },
        { threshold: [40, 60], color: COLORS_6[key][3] },
        { threshold: [60, 80], color: COLORS_6[key][4] },
        { threshold: [80, Infinity], color: COLORS_6[key][5] }
    ],
    NORM: (key) => [
        { threshold: [1, 100], color: COLORS_6[key][0] },
        { threshold: [100, 1000], color: COLORS_6[key][2] },
        { threshold: [1000, Infinity], color: COLORS_6[key][5] }
    ]
};

export class Contour {

    static id() {
        return 'contour';
    }

    constructor() {
        this.id = Contour.id();
        this.name = 'Contour';
        this.categories = {
            [CATEGORIES.confirmed.id]: {
                getWeight: dateValues => sqrt(dateValues[CATEGORIES.confirmed.id]),
                aggregation: 'SUM',
                contours: CONTOURS.NORM(CATEGORIES.confirmed.id)
            },
            [CATEGORIES.confirmedInc.id]: {
                getWeight: dateValues => linear(dateValues[[CATEGORIES.confirmedInc.id]]),
                aggregation: 'SUM',
                contours: CONTOURS.NORM(CATEGORIES.confirmed.id)
            },
            [CATEGORIES.confirmedIncPer.id]: {
                getWeight: dateValues => linear(dateValues[CATEGORIES.confirmedIncPer.id]),
                aggregation: 'MEAN',
                contours: CONTOURS.PERC(CATEGORIES.confirmed.id)
            },
            [CATEGORIES.current.id]: {
                getWeight: dateValues => sqrt(dateValues[CATEGORIES.current.id]),
                aggregation: 'SUM',
                contours: CONTOURS.NORM(CATEGORIES.current.id)
            },
            [CATEGORIES.currentPer.id]: {
                getWeight: dateValues => linear(dateValues[CATEGORIES.currentPer.id]),
                aggregation: 'MEAN',
                contours: CONTOURS.PERC(CATEGORIES.current.id)
            },
            [CATEGORIES.currentInc.id]: {
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.currentInc.id]),
                aggregation: 'SUM',
                contours: CONTOURS.NORM(CATEGORIES.current.id)
            },
            [CATEGORIES.currentIncPer.id]: {
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.currentIncPer.id]),
                aggregation: 'MEAN',
                contours: CONTOURS.PERC(CATEGORIES.current.id)
            },
            [CATEGORIES.recovered.id]: {
                getWeight: dateValues => sqrt(dateValues[CATEGORIES.recovered.id]),
                aggregation: 'SUM',
                contours: CONTOURS.NORM(CATEGORIES.recovered.id)
            },
            [CATEGORIES.recoveredPer.id]: {
                getWeight: dateValues => linear(dateValues[CATEGORIES.recoveredPer.id]),
                aggregation: 'MEAN',
                contours: CONTOURS.PERC(CATEGORIES.recovered.id)
            },
            [CATEGORIES.recoveredInc.id]: {
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredInc.id]),
                aggregation: 'SUM',
                contours: CONTOURS.NORM(CATEGORIES.recovered.id)
            },
            [CATEGORIES.recoveredIncPer.id]: {
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredIncPer.id]),
                aggregation: 'MEAN',
                contours: CONTOURS.PERC(CATEGORIES.recovered.id)
            },
            [CATEGORIES.deaths.id]: {
                getWeight: dateValues => sqrt(dateValues[CATEGORIES.deaths.id]),
                aggregation: 'SUM',
                contours: CONTOURS.NORM(CATEGORIES.deaths.id)
            },
            [CATEGORIES.deathsPer.id]: {
                getWeight: dateValues => linear(dateValues[CATEGORIES.deathsPer.id]),
                aggregation: 'MEAN',
                contours: CONTOURS.PERC(CATEGORIES.deaths.id)
            },
            [CATEGORIES.deathsInc.id]: {
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsInc.id]),
                aggregation: 'SUM',
                contours: CONTOURS.NORM(CATEGORIES.deaths.id)
            },
            [CATEGORIES.deathsIncPer.id]: {
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsIncPer.id]),
                aggregation: 'MEAN',
                contours: CONTOURS.PERC(CATEGORIES.deaths.id)
            }
        }
    }

    createLayer(data, date, layer, onClickCallback, onHoverCallback) {
        const cat = this.categories[layer.category];
        return new deck.ContourLayer({
            id: layer.id,
            getWeight: d => cat.getWeight(d[date]),
            contours: cat.contours,
            aggregation: cat.aggregation,
            updateTriggers: {
                getWeight: [date, layer]
            },
            data,
            ...layer.options
        });
    }

    getInfo() {
        return {
            id: this.id,
            name: this.name,
            options: [
                { name: 'Opacity', id: 'opacity', type: 'range', min: '0', max: '1', step: '0.01', val: '0.7' },
                { name: 'Cell Size', id: 'cellSize', type: 'range', min: '100000', max: '5000000', step: '10000', val: '500000' },
                { name: 'Elevation', id: 'zOffset', type: 'range', min: '0', max: '100', step: '0.001', val: '0.005' }
            ],
            categories: Object.keys(this.categories).map(k => { return { id: k  } })
        }
    }
}