import { COLORS_6 } from "./colors";
import { sqrt, linear, posLinear } from "./functions";
import { CATEGORIES } from "../../categories";
import { SUM, MEAN } from "./aggregations";

export class ScreenGrid {
    static id() {
        return 'screengrid';
    }

    constructor() {
        this.id = ScreenGrid.id();
        this.name = 'ScreenGrid';
        this.categories = {
            [CATEGORIES.confirmed.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.confirmed.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.confirmedInc.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.confirmedInc.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.confirmedIncPer.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.confirmedIncPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.confirmed.id]
            },
            [CATEGORIES.current.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.current.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentPer.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.currentPer.id]),
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentInc.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.currentInc.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.currentIncPer.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.currentIncPer.id]), 
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.current.id]
            },
            [CATEGORIES.recovered.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.recovered.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredPer.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.recoveredPer.id]), 
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredInc.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredInc.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.recoveredIncPer.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.recoveredIncPer.id]), 
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.recovered.id]
            },
            [CATEGORIES.deaths.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.deaths.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsPer.id]: { 
                getWeight: dateValues => linear(dateValues[CATEGORIES.deathsPer.id]), 
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsInc.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsInc.id]),
                aggregation: SUM,
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            },
            [CATEGORIES.deathsIncPer.id]: { 
                getWeight: dateValues => posLinear(dateValues[CATEGORIES.deathsIncPer.id]), 
                aggregation: MEAN,
                colorRange: COLORS_6[CATEGORIES.deaths.id]
            }
        }
    }

    createLayer(data, date, layer, onClick, onHover) {
        return new deck.ScreenGridLayer({
            id: layer.id,
            pickable: true,
            colorRange: this.categories[layer.category].colorRange,
            getWeight: d => this.categories[layer.category].getWeight(d[date]),
            aggregation: this.categories[layer.category].aggregation,
            updateTriggers: {
                getWeight: [date, layer]
            },
            transitions: {
                getWeight: 1000
            },
            onHover: info => {
                if (!info.object){
                    onHover(undefined)
                    return
                }
                const val = {
                    value: info.object.cellWeight,
                    category: layer.category,
                    x: info.x,
                    y: info.y,
                    clickable: false,
                }
                onHover(val)
            },
            data: data.filter(d => d[date][layer.category] > 0),
            ...layer.options
        });
    }

    getInfo() {
        return {
            id: this.id,
            name: this.name,
            options: [
                { name: 'Opacity', id: 'opacity', type: 'range', min: '0', max: '1', step: '0.01', val: '0.7' },
                { name: 'Cell Size Pixels', id: 'cellSizePixels', type: 'range', min: '1', max: '100', step: '1', val: '20' },
                { name: 'Cell Margin Pixels', id: 'cellMarginPixels', type: 'range', min: '0', max: '5', step: '1', val: '2' }
            ],
            categories: Object.keys(this.categories).map(k => { return { id: k } } )
        }
    }

}